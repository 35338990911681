import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-ea3cf5de"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "pageList",
  class: "ZxbList"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_ListEngine = _resolveComponent("ListEngine")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ListEngine, {
      pageList: _ctx.pageList,
      ref: "pageListRef",
      formatPageInfo: _ctx.formatPageInfo
    }, {
      queryParams: _withCtx(() => [
        _createVNode(_component_el_row, { class: "myRow" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('zxb.queryParam.code')) + "：", 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  onInput: _cache[0] || (_cache[0] = e => _ctx.pageList.queryParam.code = _ctx.valid(e)),
                  placeholder: _ctx.$t('zxb.queryParam.code_placeholder'),
                  modelValue: _ctx.pageList.queryParam.code,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pageList.queryParam.code) = $event)),
                  clearable: ""
                }, null, 8, ["placeholder", "modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('zxb.queryParam.customerId')) + "：", 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  ref: el=>_ctx.refMap.set('customerId',el),
                  modelValue: _ctx.pageList.queryParam.customerId,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.pageList.queryParam.customerId) = $event)),
                  placeholder: _ctx.$t('zxb.queryParam.customerId_placeholder'),
                  clearable: "",
                  style: {"width":"100%"}
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'customerId'}), (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item.value,
                        label: item.label,
                        value: item.value
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "myRow" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('zxb.queryParam.bz')) + "：", 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  ref: el=>_ctx.refMap.set('bz',el),
                  modelValue: _ctx.pageList.queryParam.bz,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.pageList.queryParam.bz) = $event)),
                  placeholder: _ctx.$t('zxb.queryParam.bz_placeholder'),
                  clearable: "",
                  style: {"width":"100%"}
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'bz'}), (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item.value,
                        label: item.label,
                        value: item.value
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      extendSlot: _withCtx(() => []),
      tbCols: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "F_CODE",
          label: _ctx.$t('zxb.columns.F_CODE')
        }, null, 8, ["label"]),
        _createVNode(_component_el_table_column, {
          prop: "F_CUSTOMER_ID",
          label: _ctx.$t('zxb.columns.F_CUSTOMER_ID')
        }, null, 8, ["label"]),
        _createVNode(_component_el_table_column, {
          prop: "F_DAY",
          label: _ctx.$t('zxb.columns.F_DAY')
        }, null, 8, ["label"]),
        _createVNode(_component_el_table_column, {
          prop: "F_AMOUNT",
          label: _ctx.$t('zxb.columns.F_AMOUNT'),
          "header-align": "center",
          align: "right"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(_ctx.utils.UtilPub.toAmountFormat(scope.row.F_AMOUNT,false,2)), 1)
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_table_column, {
          prop: "F_USED_AMOUNT",
          label: _ctx.$t('zxb.columns.F_USED_AMOUNT'),
          "header-align": "center",
          align: "right"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(_ctx.utils.UtilPub.toAmountFormat(scope.row.F_USED_AMOUNT,false,2)), 1)
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_table_column, {
          prop: "F_SY_AMOUNT",
          label: "剩余金额",
          "header-align": "center",
          align: "right"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(_ctx.utils.UtilPub.toAmountFormat(scope.row.F_SY_AMOUNT,false,2)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_PFBL",
          label: "赔付比例(%)"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_BZ",
          label: _ctx.$t('zxb.columns.F_BZ')
        }, null, 8, ["label"])
      ]),
      _: 1
    }, 8, ["pageList", "formatPageInfo"])
  ]))
}